import React from 'react';
import styled from 'styled-components';
import Logo from '../Logo';

export const LoadingArea = styled.div`
  position: absolute;
  inset: 0;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 500px;
    height: 60px;
    margin-bottom: 20px;
  }

  @media (max-width: 411px) {
    height: 100vh;
  }

  .loading {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(116, 174, 228, 0.3);

    border-radius: 50%;
    border-top-color: #74aee4;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;
type FullPageLoaderProps = {
  title?: string;
};
const FullPageLoader = ({ title }: FullPageLoaderProps) => {
  return (
    <LoadingArea>
      <Logo />
      <div className="loading"></div>
      {title && <h3>{title}</h3>}
    </LoadingArea>
  );
};

export default FullPageLoader;
